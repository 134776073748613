.b-tooltip {
    background-color: #646E75 !important;
    border-radius: 6px !important;
    padding: 4px 8px !important;
    font-size: 12px;
    line-height: 18px;
    font-family: 'Montserrat Regular', Arial, sans-serif;
    max-width: 650px;
    text-align: center;
}

.b-tooltip-roadmap {
    max-width: 650px;
    white-space: normal;
    text-align: center;
}

.b-tooltip:after {
    display: none;
}

.b-tooltip:before {
    display: none;
}
