
@font-face {
    font-family: 'Montserrat Semibold';
    src: url('assets/fonts/montserrat/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Bold';
    src: url('assets/fonts/montserrat/Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Regular';
    src: url('assets/fonts/montserrat/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Medium';
    src: url('assets/fonts/montserrat/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed Bold';
    src: url('assets/fonts/Roboto/RobotoCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed Regular';
    src: url('assets/fonts/Roboto/RobotoCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
